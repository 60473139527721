import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, tap, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriberGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService, ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return combineLatest(this.authService.authState, this.authService.initialized)
        .pipe(
          take(1),
          map(([authState, init]) => authState),
          map(authState => !!authState),
          tap(loggedIn => {
            if (!loggedIn) {
              this.router.navigate(['/login']);
            } else {
              const activeUser = this.authService.activeUser;
              if (!activeUser?.paymentInfoRequired) {
                return true;
              } else {
                this.router.navigate(['/profile']);
                return;
              }
            }
          })
        );
  }
}
