import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AngularFireStorageModule, } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, CONFIG, DEBUG_MODE, APP_VERSION, COLLECTION_ENABLED, APP_NAME } from '@angular/fire/analytics';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { version, name } from 'package.json';
import * as Sentry from "@sentry/angular";

Sentry.init({
  dsn: "https://61b2e52090cd46eab059193e7493c3bf@o354533.ingest.sentry.io/5652672"
});

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      // Enforce iOS only stylings to make dev easier since everything is custom design elements anyway
      // (No need to force each element to also override Android stylings in addition to iOS stylings)
      mode: 'ios',
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // Uncomment for app to hit Firebase Callable Functions serving locally
    // { provide: FUNCTIONS_ORIGIN, useValue: 'http://localhost:5000' },
    // { provide: DEBUG_MODE, useValue: true },
    { provide: APP_VERSION, useValue: version},
    { provide: APP_NAME, useValue: name},
    AngularFireAuthGuard,
    ScreenTrackingService,
    UserTrackingService,
  { provide: ErrorHandler, useValue: Sentry.createErrorHandler({showDialog: false}) },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
