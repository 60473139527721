import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { redirectUnauthorizedTo, canActivate } from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubscriberGuard } from './core/guards/subscriber.guard';
import { AdminGuard } from './core/guards/admin.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    canActivate: [SubscriberGuard],
  },
  {
    path: 'favorites',
    loadChildren: () => import('./pages/favorites/favorites.module').then(m => m.FavoritesPageModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    canActivate: [SubscriberGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'workout/:id',
    loadChildren: () => import('./pages/workout/workout.module').then(m => m.WorkoutPageModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    canActivate: [SubscriberGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    canLoad: [AdminGuard],
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/legal/privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/legal/terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/admin/users/users.module').then(m => m.UsersPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/admin/categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'workouts',
    loadChildren: () => import('./pages/admin/workouts/workouts.module').then( m => m.WorkoutsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
