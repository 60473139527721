import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../models/user';
import { map, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { UtilityService } from './utility.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private configService: ConfigService,
    private utilityService: UtilityService,
    private fns: AngularFireFunctions, ) { }

  async signupUser(email: string, password: string, firstName: string, lastName: string): Promise<any> {
    try {
      const newUserCredential = await this.afAuth.createUserWithEmailAndPassword(email, password);

      //Create new user object
      let newUser = {
        id: newUserCredential.user.uid,
        createdAt: this.utilityService.firebaseTimestamp, //Set to todays date
        email,
        firstName,
        lastName,
      };

      /**
       * If trainer is registering and their email is in the .env file,
       * automatically mark them as an admin. This will allow them 
       * to join the app as an admin and not go through the Stripe workflow.
       */
      const trainerEmail = this.configService.trainerEmail || null;
      if (trainerEmail && trainerEmail.trim().toLowerCase() === newUser.email.trim().toLowerCase()) {
        // @ts-ignore
        newUser.isAdmin = true;
      }

      //Save new user data to firestore
      await this.afs.doc(`/users/${newUserCredential.user.uid}`).set(newUser);
      console.log('User created successfully: ', newUserCredential.user.uid);
    }
    catch (error) {
      console.log('signupUser Error: ', error);
      throw new Error(error);
    }
  }

    /**
   * Fetches a user document given a user id
   * @param id Document ID of the user to fetch
   */
  public getUserById(id: string) {
    return this.afs.doc<any>(`users/${id}`).snapshotChanges()
      .pipe(map(userDoc => {
        if (userDoc.payload.exists) {
          return new User(userDoc.payload.data());
        } else {
          return null;
        }
      }));
  }

  public updateUser(user: Partial<User>) {
    const cleansedUser = Object.assign({}, user);
    return this.afs.doc(`/users/${user.id}`).update(cleansedUser);
  }

  public async cancelStripeSubscription(user: User) {
    // Note this is a Callable Function:
    // https://firebase.google.com/docs/functions/callable
    const loader = await this.utilityService.createLoader();
    const callable = this.fns.httpsCallable('cancelStripe');
    loader.present().then(() => {
      callable({user}).toPromise()
      .then((success) => {
        console.log(success);
        loader.dismiss();
        this.utilityService.showToast('Subscription cancelled.');
      })
      .catch((error) => {
        console.error(error);
        loader.dismiss();
        this.utilityService.showToast('Unable to cancel your subscription.');
      })
    })

  }
}
