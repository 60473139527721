import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  appDisplayName: 'Holly J Fitness',
  appURL: 'www.hollyjfitnessapp.com',
  supportEmail: 'holly@hollyjfitness.com',
  supportName: 'Holly',
  trainerName: 'Holly',
  paymentRequiredHtml: `It's <b>$0.99</b> for the first 30 days and you can cancel any time.<br><br>After that, it's just $19.99 per month.`,
  customCheckoutIcon: true,
  customHomeIcon: true,
  customerPortalEnabled: true,
  mode: 'netflix',
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCr0XH9eonNq_lfFCynVxmZGe0EYL6SSQY',
    authDomain: 'holly-j-fitness.firebaseapp.com',
    databaseURL: 'https://holly-j-fitness.firebaseio.com',
    projectId: 'holly-j-fitness',
    storageBucket: 'holly-j-fitness.appspot.com',
    messagingSenderId: '910822721763',
    appId: '1:910822721763:web:ed0760f11265c767699dd6',
    measurementId: 'G-X8R389MKR8',
  },
  stripeConfig: {
    priceId: 'plan_GYo5atKy5Effid', // $19.99
    publishableKey:
      'pk_live_51IJfhsA8WjBR5vikonTHdlmfc6MTznzGBEM3TNYWzqdPDqxaWv9cCPa6UbIBI25ICoxMjCmBczMKbHuuUwPwKWpr00VvIgb5Z2', // CB's key, do not change
    connectedId: 'acct_1CSqXJBML0NiC25i',
    platformFee: 0,
    couponId: 'ZFUaDWZs', // First month $0.99
  },
};
