import firebase from 'firebase/app';
import * as moment from 'moment';
// tslint:disable: max-line-length
export interface StripeTransaction {
  customerId: string;
  subscriptionId: string;
  checkoutSessionId: string;
  createdAt: firebase.firestore.FieldValue | Date; // Date when creating, FieldValue after
}

export interface HJFSubscription {
  startOn: string;
  endOn?: string; // if current subscription is active, it won't have endOn
  stripe: StripeTransaction; // Stripe details that started this subscription
}
export class User {
  id: string;
  createdAt:
    | firebase.firestore.FieldValue
    | { nanoseconds: number; seconds: number };
  email: string;
  firstName: string;
  lastName: string;
  isAdmin?: boolean;
  isTest?: boolean; // is test account; remove from admin reporting
  isTeamHJF?: boolean;
  subscription?: HJFSubscription[]; // will be a value once user completes initial Stripe Checkout process
  favoriteWorkouts?: { [workoutId: string]: firebase.firestore.FieldValue }; // key value pairs with value being timestmap of favorite
  completedWorkouts?: {
    [workoutId: string]: Date[] | firebase.firestore.FieldValue[];
  }; // key value pairs with value being array of timestamps when workout marked complete

  constructor(data: Partial<User>) {
    Object.assign(this, data);
  }

  /**
   * User is required to complete payment information before entering app
   */
  public get paymentInfoRequired(): boolean {
    return this.isAdmin ? false : this.isNonSubscriber;
  }

  public get showSubscriptionPaused(): boolean {
    return this.isFormerSubscriber && !this.isTeamHJF;
  }

  /**
   * Most recent subscription, whether or not it's active
   */
  public get latestSubscription(): HJFSubscription {
    if (!this.subscription || !this.subscription.length) {
      return null;
    }
    return this.subscription[this.subscription.length - 1];
  }

  /**
   * User has never had a subscription
   */
  public get isNonSubscriber(): boolean {
    return !this.latestSubscription;
  }

  /**
   * User has a currently active subscription (live Stripe subscription and full access to content)
   */
  public get isActiveSubscriber(): boolean {
    if (!this.latestSubscription) {
      return false;
    }
    return this.latestSubscription.endOn ? false : true;
  }

  /**
   * User setup Stripe subscription in the past but is no longer an active user.
   */
  public get isFormerSubscriber(): boolean {
    return !this.isNonSubscriber && !this.isActiveSubscriber;
  }

  /**
   * Human readable subscription name. Using for logging purposes only now.
   */
  public get subscriptionStatus():
    | 'Non Subscriber'
    | 'Former Subscriber'
    | 'Active Subscriber'
    | 'Team HJF'
    | 'Admin' {
    if (this.isAdmin) {
      return 'Admin';
    }
    if (this.isNonSubscriber) {
      return 'Non Subscriber';
    }
    if (this.isTeamHJF) {
      return 'Team HJF';
    }
    if (this.isActiveSubscriber) {
      return 'Active Subscriber';
    }
    if (this.isFormerSubscriber) {
      return 'Former Subscriber';
    }
  }

  /**
   * Stripe details of active subscription (if there is one)
   */
  public get activeStripeDetails(): StripeTransaction {
    return this.isActiveSubscriber ? this.latestSubscription.stripe : null;
  }

  /**
   * Start a new subscription. Fires after Stripe Checkout event successful. Updates Stripe and subscription data on user record.
   * @param stripeCustomerId
   * @param stripeSubscriptionId
   * @param stripeCheckoutId
   */
  public startNewSubscription(
    stripeCustomerId: string,
    stripeSubscriptionId: string,
    stripeCheckoutId: string
  ): void {
    if (!stripeCustomerId || !stripeSubscriptionId || !stripeCheckoutId) {
      console.error(
        'Unable to call user.startNewSubscription() due to missing paramter(s)'
      );
      return;
    }

    const stripeTransaction: StripeTransaction = {
      customerId: stripeCustomerId,
      subscriptionId: stripeSubscriptionId,
      checkoutSessionId: stripeCheckoutId,
      createdAt: new Date(),
    };

    const sub = this.subscription ? this.subscription : [];
    const startOfMonth = moment().startOf('month').format('YYYY-MM');
    const newSub: HJFSubscription = {
      startOn: startOfMonth,
      stripe: stripeTransaction,
    };

    // If existing sub, ensure it has an end date
    if (sub.length) {
      sub.forEach((existingSub) => {
        if (!existingSub.endOn) {
          existingSub.endOn = startOfMonth;
        }
      });
    }

    sub.push(newSub);
    this.subscription = sub;
  }

  public cancelSubscription(): void {
    if (!this.isActiveSubscriber) {
      return;
    }
    this.latestSubscription.endOn = moment().startOf('month').format('YYYY-MM');
  }
}
