import { User } from './../models/user';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { map, switchMap, tap, take, filter } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserService } from './user.service';
import { UtilityService } from './utility.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _authState = new BehaviorSubject<firebase.User>(null);
  public readonly authState = this._authState.asObservable();

  private _user = new BehaviorSubject<User>(null);
  public readonly user = this._user.asObservable();

  private _initialized = new BehaviorSubject<boolean>(null);
  public readonly initialized = this._initialized.asObservable()
    .pipe(
      filter(initialized => !!initialized),
      take(1)
    );

  public userLogout$ = new Subject;

  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private utilityService: UtilityService, )
  {
    this.afAuth.authState
    .pipe(
      map((user: firebase.User) => {
        console.log('FirebaseUser: ', user);
        this._authState.next(user);
        return user;
      }),
      switchMap((user: firebase.User) => {
        return user ? this.userService.getUserById(user.uid) : Promise.resolve(null);
      })
    )
    .subscribe((user: User) => {
      this._user.next(user);
      this._initialized.next(true);

      if (user) {
        this.utilityService.setSentryUser(user);
      }

      console.log('user', user);
    });
  }

  public get currentAuthState() {
    return this._authState.getValue();
  }

  public get isAuthenticated(): boolean {
    return !!this.currentAuthState;
  }

  public get activeUser(): User {
    return this._user.getValue();
  }

  async loginUser(email: string, password: string): Promise<any> {
    await this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async resetPassword(email: string): Promise<void> {
    await this.afAuth.sendPasswordResetEmail(email);
  }

  async logoutUser(): Promise<void> {
    this.userLogout$.next(true);
    await this.afAuth.signOut();
  }

}
