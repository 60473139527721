import { Injectable } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { version } from 'package.json';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';
import { User } from '../models/user';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private afs: AngularFirestore,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController) {
      this.initSentry();
    }

  public async showToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  public async createLoader(msg?: string) {
    const loader = await this.loadingCtrl.create({
      message: msg,
    });
    return loader;
  }

  public generateFirestoreId(): string {
    return this.afs.createId();
  }

  public get firebaseTimestamp(): firebase.firestore.FieldValue {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  public get baseUrl(): string {
    const host = location.host; // e.g. "app.airtrainer.com"
    const scheme = `${location.protocol}//`; // e.g. https://
    const baseUrl = scheme + host; // e.g. https://app.airtrainer.com
    return baseUrl;
  }

  public trackByFn(index, item) {
    return item && item.id ? item.id : null;
  }

  /**
   * Need to add `ngsw-bypass` as a query paramater so the service worker passes the request directly to the browser to handle.
   * Otherwise, in Safari, videos will not load. At time of writing, videos do not work in Safari with service workers.
   * Documentation here: https://github.com/angular/angular/blob/master/aio/content/guide/service-worker-devops.md
   */
  public patchVideoUrl(url: string): string {
    if (!url) { return ''; }
    return `${url}&ngsw-bypass=true`;
  }

  public get appVersion(): string {
    return `App Version ${version}${!environment.production ? ' (dev)' : ''}`;
  }

  public initSentry(): void {
    Sentry.setTag('version', version);
    Sentry.setTag('app', environment.appDisplayName);
  }

  public setSentryUser(user: User): void {
    Sentry.configureScope(scope => scope.setUser({id: user.id, email: user.email}));
    Sentry.setTag('subscription_status', user.subscriptionStatus);
  }
}
