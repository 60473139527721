import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';

import { Platform } from '@ionic/angular';
import { AuthService } from './core/services/auth.service';
import { version } from '../../package.json';
import { SwUpdate } from '@angular/service-worker';
import { UtilityService } from './core/services/utility.service';
import { environment } from './../environments/environment';

const { SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      src: environment.customHomeIcon ? '../assets/home-icon.svg' : null, //use src for custom made icons
      icon: !environment.customHomeIcon ? 'home' : null, // if no custom, use ionicon's
      adminRequired: false,
    },
    {
      title: 'Favorites',
      url: '/favorites',
      icon: 'heart', //use 'icon' for ion-icon provided by Ionic
      adminRequired: false,
    },
    {
      title: 'Profile',
      url: '/profile',
      icon: 'person-circle-outline',
      adminRequired: false,
    },
    {
      title: 'Admin',
      url: '/admin',
      icon: 'cog',
      adminRequired: true,
    },
  ];

  constructor(
    private platform: Platform,
    public authService: AuthService, // add here to ensure authService is always initialized
    private swUpdate: SwUpdate,
    public utilityService: UtilityService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide().catch((error) => {
        console.error('SplashScreen Error: ', error);
      });

      console.log('App Version: ', version);

      this.swUpdate.available.subscribe((event) => {
        // console.log('[SwUpdate] current version is', event.current);
        // console.log('[SwUpdate]available version is', event.available);
        this.utilityService.showToast('Updating app...');
        // https://angular.io/api/service-worker/SwUpdate#activateUpdate
        this.swUpdate.activateUpdate().then(() => document.location.reload());
      });
    });
  }
}
