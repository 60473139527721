import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public appDisplayName = environment.appDisplayName;
  public supportEmail = environment.supportEmail;
  public supportName = environment.supportName;
  public trainerName = environment.trainerName;
  public trainerEmail = environment.trainerEmail;
  public appURL = environment.appURL;
  public mode = environment.mode;
  public isMonthlyMode = environment.mode === 'monthly';
  public isNetflixMode = environment.mode === 'netflix';
  public activeSubscriptionRequiredForContent = this.isNetflixMode;
  public isCustomerPortalEnabled = environment.customerPortalEnabled;

  constructor() { }
}
